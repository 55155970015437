<template>
  <ul class="jwl-menu-overlay">
    <li
      class="jwl-menu-overlay__list-item"
      v-for="sublist in baseConcentrations.menu()"
      :key="`menu-${baseMenuName}-${sublist.modifier}`"
      :class="submenuListItemClass(sublist.modifier)">
      <div class="jwl-menu-overlay__list-item-label">
        {{$root.$t(`menu.${sublist.translatable}`)}}
      </div>

      <ul class="jwl-menu-overlay__sublist" v-if="sublist.hasChildren">
        <li
          class="jwl-menu-overlay__sublist-item"
          v-for="submenu in sublist.children"
          :key="`submenu-${baseMenuName}-${sublist.modifier}-${submenu.id}`"
          @click="closeOverlay">
          <router-link class="jwl-menu-overlay__sublist-item-label"
            :to="{ name: baseMenuName, params: { concentration: submenu.code } }">
            {{submenu.label}}
            <small>
              {{submenu.code}} // {{formattedDate(submenu.startDate)}} - {{formattedDate(submenu.endDate)}}
            </small>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    baseMenuName: String,
  },
  methods: {
    submenuListItemClass (modifier) {
      return `jwl-menu-overlay__list-item--${modifier}`;
    },
    closeOverlay () {
      this.$emit('close-overlay');
    },
    formattedDate (dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
  },
  computed: mapState({
    baseConcentrations: (state) => state.base.baseConcentrations,
  }),
};
</script>

<style lang="scss">
@use "src/styles/variables";
@use "../styles/mixins";

.jwl-menu-overlay {
  $root: &;

  align-items: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 33.33%;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-columns: 33.33%;
  grid-template-rows: auto;
  justify-content: flex-start;
  left: 0;
  list-style: none;
  padding: 1rem 1.5rem;
  top: 100%;

  @include mixins.mq(variables.$mq-phone) {
    grid-auto-columns: 100%;
    grid-auto-flow: row;
    grid-template-columns: 100%;
    padding: 1rem 2.5vw;
  }

  ul {
    list-style: none;
  }

  &__list-item {
    --node-theme: var(--color-primary);

    display: flex;
    flex: 0 0 calc(33.33% - 1.5rem);
    flex-flow: column;
  }

  &__list-item-label {
    border-bottom: 1px solid;
    color: var(--node-theme);
    margin-bottom: 1rem;
    padding: .4rem 0;
    text-transform: uppercase;
  }

  &__sublist {
    padding: 0 0;

    &--contains-toggle {
      padding: 0 0 0 1.5rem;
    }
  }

  &__sublist-item {
    margin-bottom: 1rem;
  }

  &__sublist-item-label {
    color: var(--color-gray-900);
    display: block;
    font-size: 1rem;
    text-decoration: none;
    transition: color .25s;

    &:hover {
      color: var(--node-theme);
    }

    small {
      color: var(--color-gray-600);
      display: block;
      margin-top: 0;
      width: 100%;
    }
  }
}

</style>
